import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './styles/main.scss';

const { REACT_APP_REDIRECT_URL, REACT_APP_AUTH_0_DOMAIN, REACT_APP_AUTH_0_CLIENT_ID } = process.env;

const IndexComponent = () => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : (window.location.href = REACT_APP_REDIRECT_URL!)
    );
  };

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH_0_DOMAIN!}
      clientId={REACT_APP_AUTH_0_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      <App />
    </Auth0Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <IndexComponent />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
