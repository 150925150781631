import { createMuiTheme } from '@material-ui/core/styles';

// Custom theme
const themeSettings = {
  palette: {
    primary: {
      main: '#0072c9',
    },
    secondary: {
      main: '#73add8',
    },
    success: {
      main: '#299934',
    },
    error: {
      main: '#ff2744',
    },
  },
};

const theme = createMuiTheme(themeSettings);

export default theme;
