import React, { useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ThemeProvider } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/common/Loading';
import SignInPage from './pages/SignInPage';
import AndroidConfirmationDialog from './components/AndroidConfirmationDialog';
import Main from './Main';
import theme from './theme';

const {
  REACT_APP_GRAPH_QL_API_URL,
  REACT_APP_REGION,
  REACT_APP_COGNITO_IDENTITY_POOL,
  REACT_APP_UPLOAD_BUCKET,
  REACT_APP_AUTH_0_DOMAIN,
} = process.env;

Storage.configure({
  bucket: REACT_APP_UPLOAD_BUCKET,
  region: REACT_APP_REGION,
});

const ThemeWrapper = (children: JSX.Element) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

const App: React.FunctionComponent = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const page = window.location.pathname;

  useEffect(() => {
    Auth.configure({
      region: REACT_APP_REGION,
      identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL,
      refreshHandlers: {
        [REACT_APP_AUTH_0_DOMAIN as string]: async () => {
          const claims = await getIdTokenClaims();
          return {
            // eslint-disable-next-line no-underscore-dangle
            token: claims.__raw,
            expires_at: claims.exp! * 1000,
          };
        },
      },
    });
  }, [getAccessTokenSilently, getIdTokenClaims]);

  if (isLoading) {
    return ThemeWrapper(<Loading />);
  }

  if (page === '/user-agreement') {
    return ThemeWrapper(<AndroidConfirmationDialog />);
  }

  if (!isAuthenticated) {
    localStorage.setItem('loginRedirect', window.location.pathname);
    return ThemeWrapper(<SignInPage />);
  }
  const config = {
    url: REACT_APP_GRAPH_QL_API_URL!,
    region: REACT_APP_REGION!,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: async () => getAccessTokenSilently(),
    },
  } as any;

  const client = new ApolloClient({
    link: ApolloLink.from([createAuthLink(config), createSubscriptionHandshakeLink(config)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return ThemeWrapper(
    <ApolloProvider client={client}>
      <Main />
    </ApolloProvider>
  );
};

export default App;
