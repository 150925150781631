import { Typography, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React from 'react';
import Button from './sky/Button';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '800px',
      padding: theme.spacing(2),
      margin: theme.spacing(2, 'auto'),
    },
    checkbox: {
      display: 'block',
      margin: theme.spacing(3, 0),
    },
    terms: {
      margin: theme.spacing(3, 0),
    },
    sectionTitle: {
      margin: theme.spacing(4, 0, 1),
    },
  })
);

const AndroidConfirmationDialog = () => {
  const classes = useStyles();
  const query = useQuery();

  const auth0Domain = query.get('auth0_domain');
  const auth0State = query.get('state');

  const schema = yup.object().shape({
    confirm: yup.boolean().default(false).notRequired(),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getRegisterProps = (name: string, type: string) => {
    const { ...registerProps } = register(name);
    switch (type) {
      case 'radio':
      case 'checkbox':
        return {
          ...registerProps,
        };
      default:
        return {
          ...registerProps,
          error: !!errors[name],
          helperText: errors[name]?.message,
          variant: 'standard',
          fullWidth: true,
          margin: 'normal',
        };
    }
  };

  const onAcceptanceChange = () => {
    const privateCheck = document.getElementById('accepting-confirm')! as HTMLInputElement;
    privateCheck.click();
  };

  if (!auth0Domain || !auth0State) {
    return <p>Error</p>;
  }

  return (
    <form
      id="add-task-form"
      noValidate
      method="post"
      action={`https://${auth0Domain}/continue?state=${auth0State}`}
      className={classes.root}
    >
      <Typography variant="h4" id="confirmation-dialog-title" gutterBottom>
        User agreement
      </Typography>
      <Typography>
        Do you accept the terms? If you do not accept, you will not be able to proceed
      </Typography>
      <div className={classes.terms}>
        <Typography component="h3" className={classes.sectionTitle} variant="h6">
          What happens during the recordings?
        </Typography>
        <Typography gutterBottom>
          <ul>
            <li>
              We will ask you/your friends/your family to record certain activities. These
              activities will be given as tasks within the Video Collection app.
            </li>
            <li>
              We will record your audio and video for the duration of the session, for a maximum of
              one minute, or when you say stop, whichever comes first.
            </li>
          </ul>
        </Typography>
        <Typography component="h3" className={classes.sectionTitle} variant="h6">
          How will the video recordings be used?
        </Typography>
        <Typography gutterBottom>
          We will use the recordings to -
          <ul>
            <li>
              Develop our products and services so they recognise the poses made by people in front
              of them; and
            </li>
            <li>
              Test how effective the products and services that are being developed by Sky employees
              or third-parties are at detecting activities in front of the camera.
            </li>
          </ul>
          We will share video recordings with suppliers so that they can improve the technology they
          are building for us.
          <br />
          <br />
          We will delete all video recordings at the end of the trials and ensure that any of the
          above mentioned suppliers also delete the recordings too.
        </Typography>
        <Typography component="h3" className={classes.sectionTitle} variant="h6">
          What information will be collected about me/my friends/my family?
        </Typography>
        <Typography gutterBottom>
          <ul>
            <li>
              In addition to the activities that you record for us, we will also ask you and any
              individuals taking part in the prescribed activities to provide:
              <ul>
                <li>Environment where the recording took place</li>
              </ul>
            </li>
          </ul>
          If at any time we need to obtain any further personal data from you other than what is
          listed in this notice, we will contact you and provide you with detail of that as well as
          request your consent for this data to be processed.
          <br />
          <br />
          For further detail on how we process data and how to exercise your data subject rights,
          please visit{' '}
          <a target="_blank" rel="noreferrer" href="https://www.sky.com/privacy">
            www.sky.com/privacy
          </a>
          <br />
          <br />I have read the information above and consent to the collection and transfer of
          personal data as described in this notice.
        </Typography>
      </div>
      <FormControl className={classes.checkbox}>
        <FormControlLabel
          control={<Checkbox color="primary" onChange={() => onAcceptanceChange()} />}
          label="I accept terms above"
        />
        <input
          type="checkbox"
          id="accepting-confirm"
          hidden
          value="yes"
          {...getRegisterProps('confirm', 'checkbox')}
        />
      </FormControl>
      <Button type="submit" form="add-task-form" color="primary">
        Submit
      </Button>
    </form>
  );
};

export default AndroidConfirmationDialog;
